import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import { Link } from "gatsby"
import '../styles/grid.scss'
import '../styles/index.scss'

const CookiePolicy = () => {
    return (
        <Layout>
            <Head title="Çerez Politikası" />
            <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-text">
                <h1>Çerez Politikası</h1>
                <p></p>
              </div>
            </div>
            <div className="col-12 left">
             
            Çerez Politikası
Çerez Politikamız, <Link to="/privacy-policy">Gizlilik Politikamızın</Link> bir parçasını oluşturur. 
Çerez (Cookie) Nedir?
Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Size daha iyi, hızlı ve güvenli bir deneyim sağlamak için, çoğu internet sitesi gibi biz de çerezler kullanıyoruz. Çerez, bir web sitesini ziyaret ettiğinizde cihazınıza (örneğin; bilgisayar veya cep telefonu) depolanan küçük bir metin dosyasıdır. Çerezler, bir web sitesini ilk ziyaretiniz sırasında tarayıcınız aracılığıyla cihazınıza depolanabilirler. Aynı siteyi aynı cihazla tekrar ziyaret ettiğinizde tarayıcınız cihazınızda site adına kayıtlı bir çerez olup olmadığını kontrol eder. Eğer kayıt var ise, kaydın içindeki veriyi ziyaret etmekte olduğunuz web sitesine iletir. Bu sayede web sitesi, sizin siteyi daha önce ziyaret ettiğinizi anlar ve size iletilecek içeriği de ona göre tayin eder.
Çerezler Neden Kullanılır?
Bazı çerezler, daha önceki ziyaretlerinizde kullandığınız tercihlerin web sitesi tarafından hatırlanmasını sağlayarak, sonraki ziyaretlerinizin çok daha kullanıcı dostu ve kişiselleştirilmiş bir deneyim sunmasını sağlar.
Ayrıca, web sitesinde bulunan üçüncü taraflara ait linkler, bu üçüncü taraflara ait gizlilik politikalarına tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk cember.app’a ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.
Çerez Türleri
Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel olarak 4 ana grupta toplanmaktadır:
Oturum Çerezleri: Internet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için gereklidir.
Performans Çerezleri: Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve internet sitesinin performansını arttırma amacıyla kullanılmaktadır.
Fonksiyonel Çerezler: Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve internet sitesi kapsamında kullanıcılara gelişmiş Internet özellikleri sağlanmasını hedeflemektedir.
Reklam Ve Üçüncü Taraf Çerezleri: Üçüncü parti tedarikçilere ait çerezlerdir ve internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.
Çerezlerin Kullanım Amaçları
cember.app tarafından kullanılmakta olan çerezlere ait kullanım amaçları aşağıdaki gibidir:
Güvenlik amaçlı kullanımlar:  cember.app, sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden çerezler kullanabilmektedir.
İşlevselliğe yönelik kullanımlar: cember.app, sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.
Performansa yönelik kullanımlar:  cember.app, sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.
Reklam amaçlı kullanımlar:  cember.app, kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.
Çerezleri Kontrol Etme ve Silme
Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da çerezleri engellemek veya silmek için tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul etme ya da bir web sitesi cihazınıza çerez depolamayı talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar. Aynı zamanda daha önce tarayıcınıza kaydedilmiş çerezlerin silinmesi de mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin işlemler kullandığınız tarayıcıya göre değişebilmektedir. Bazı popüler tarayıcıların çerezlere izin verme ya da çerezleri engelleme veya silme talimatlarına aşağıdaki linklerden ulaşılması mümkündür.
Platform’da kullanılan Çerezlere dair tercihlerin ne şekilde yönetebileceğine dair bilgiler aşağıdaki gibidir:
 
Ziyaretçiler, Site’yi görüntüledikleri tarayıcı ayarlarını değiştirerek çerezlere ilişkin tercihlerini kişiselleştirme imkanına sahiptir. Eğer kullanılmakta olan tarayıcı bu imkânı sunmaktaysa, tarayıcı ayarları üzerinden Çerezlere ilişkin tercihleri değiştirmek mümkündür. Böylelikle, tarayıcının sunmuş olduğu imkanlara göre farklılık gösterebilmekle birlikte, veri sahiplerinin çerezlerin kullanılmasını engelleme, çerez kullanılmadan önce uyarı almayı tercih etme veya sadece bazı Çerezleri devre dışı bırakma ya da silme imkanları bulunmaktadır. Bu konudaki tercihler kullanılan tarayıcıya göre değişiklik göstermekle birlikte genel açıklamaya <a target="_blank" rel="noreferrer" href="https://www.aboutcookies.org/">https://www.aboutcookies.org/</a> adresinden ulaşmak mümkündür. Çerezlere ilişkin tercihlerin, ziyaretçinin Site’ye erişim sağladığı her bir cihaz özelinde ayrı ayrı yapılması gerekebilecektir.
 
Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan dilendiği zaman öğrenilebilmektedir.
Bu politikanın en son güncellendiği tarih: 10-09-2020

      
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default CookiePolicy